import React from "react";
import BlogRoll from "../BlogRoll";
import { graphql, StaticQuery } from "gatsby";

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query GeekingOutRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { category: { eq: "geekingout" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "DD MMMM YYYY")
                  thumbnail
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <BlogRoll data={data} count={count} title="Jeez, what a geek" />
      )}
    />
  );
};
